/* jshint ignore:start */
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                // Initialize Lozad
                const observer = lozad('.lozad', {
                    rootMargin: '30px 0px',
                    loaded: function () {
                        if ($('.grid').length) {
                            setTimeout(function() {
                                $('.grid').isotope('layout');
                            }, 250);
                        }
                    }
                });
                observer.observe();

                // Mobile Menu
                $(".hamburger-button").click(function () {
                    $('.main-nav-wrap').toggleClass('expand');
                    $('.hamburger-button').toggleClass('open');
                    $(document.body).toggleClass('menu-open');
                });

                $(".menu-item-has-children").append("<div class='toggle-menu-link'></div>");

                $('.toggle-menu-link').click(function (e) {
                    var childMenu = e.currentTarget.parentNode.children[1];
                    if ($(childMenu).hasClass('visible')) {
                        $(childMenu).removeClass("visible");

                        $(e.currentTarget.parentNode.children[3]).removeClass("visible");
                    } else {
                        $(childMenu).addClass("visible");

                        $(e.currentTarget.parentNode.children[2]).removeClass("visible");
                        $(e.currentTarget.parentNode.children[3]).addClass("visible");
                    }
                });


                // Hero image animation

                $('.hero-wrapper').waitForImages({
                    each: function (loaded, count, success) {
                        $(this).closest(".hero-wrapper").addClass('loaded');
                    },
                    waitForAll: true
                });


                // Data sheets download
                $('#product-download-select').change(function () {
                    $($("#product-download-select option:selected")).each(function () {
                        if ($(this).val())
                            $(".product-datasheets .download-button").attr('href', $(this).val()).show().fadeTo(400, 1);
                        else
                            $(".product-datasheets .download-button").attr('href', '#').fadeTo("slow", 0).hide();
                    });
                });


                // Google analytics tracking
                $("body").on("click", "#side-static-buttons .quote-link", function () {
                    ga('send', 'event', {
                        eventCategory: 'Free Quote',
                        eventAction: 'Clicked button from side link',
                    });
                });

                $("body").on("click", ".top-links .quote-link", function () {
                    ga('send', 'event', {
                        eventCategory: 'Free Quote',
                        eventAction: 'Clicked button from top header links',
                    });
                });

                $("body").on("click", "#tel a, .js-tel", function () {
                    ga('send', 'event', {
                        eventCategory: 'Call',
                        eventAction: 'Clicked telephone number',
                    });
                });





            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

                // Log to a cookie that home page animation has been viewed
                if (!Cookies.readCookie('seen-home-animation'))
                    Cookies.createCookie('seen-home-animation', true, 30);
                else
                    $(".page-template-template-home .main-nav, .page-template-template-home .top-links, .carousel.slide, .carousel-l, .logo-holder, .curtain-middle").css("animation", "none").css("opacity", 1).css("transform", "scaleX(1)"); // Remove animation

            }
        },
        // Portfolio single page
        'single_portfolio': {
            finalize: function () {
                // Portfolio Carousel
                $('.carousel').carousel();
                $('.carousel').on('slide.bs.carousel', function (e) {
                    $('.carousel').animate({height: $(e.relatedTarget).outerHeight()});
                });
            }
        },
        // Product page template
        'page_template_template_product': {
            finalize: function () {

                // Light carousel

                var galleryAutoplaySlider = $('#gallery-carousel').lightSlider({
                    auto: true,
                    pause: 4000,
                    loop: true,
                    item: 4,
                    pager: true,
                    controls: false,
                    pauseOnHover: true,
                    enableDrag: true,
                    slideMove: 1,
                    slideMargin: 20,
                    onBeforeSlide: function (el) {
                        $('#current').text(el.getCurrentSlideCount());
                    },
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                item: 3

                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                item: 2

                            }
                        },
                        {
                            breakpoint: 575,
                            settings: {
                                item: 1
                            }
                        }
                    ]
                });

                var testimonialsAutoplaySlider = $('#gallery-testimonials').lightSlider({
                    auto: false,
                    loop: true,
                    item: 3,
                    pager: true,
                    controls: false,
                    pauseOnHover: true,
                    enableDrag: true,
                    slideMove: 1,
                    slideMargin: 20,

                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                item: 1

                            }
                        }
                    ]
                });

                // slider control arrows
                $('#goToPrevSlide').on('click', function () {
                    galleryAutoplaySlider.goToPrevSlide();
                });
                $('#goToNextSlide').on('click', function () {
                    galleryAutoplaySlider.goToNextSlide();
                });

                // gallery lightbox
                $("#modal-carousel").carousel({interval: false});

                // change modal title when slide changes
                $("#modal-carousel").on("slid.bs.carousel", function () {
                    $("#modal-title").html($(this).find(".active img").attr("title"));
                });

                // when clicking a thumbnail
                $(".row .thumbnail, .open-gallery-modal").click(function (e) {

                    // to prevent page scrolling to top on click
                    e.preventDefault();

                    clicked_img = $(this).data('large');
                    carousel_items_html = '';


                    // loop though each item on parent gallery
                    $("#gallery-carousel .lslide .thumbnail").each(function (i) {

                        current_img = $(this).data('large');
                        title_img = $(this).attr("title");
                        classes = 'carousel-item';

                        // if item in loop same as clicked then make this active
                        if (clicked_img == current_img) {
                            classes += ' active';
                            $("#modal-title").html(title_img);
                        }

                        if (!clicked_img && i == 0) {
                            classes += ' active';
                            $("#modal-title").html(title_img);
                        }

                        // build items html
                        carousel_items_html += '<div class="' + classes + '"><img class="d-block" title="' + title_img + '" src="' + current_img + '"></div>';

                    });

                    // add gallery items into the modals carousel holder
                    $("#modal-gallery .carousel-inner").html(carousel_items_html);

                    // show the modal
                    $("#modal-gallery").modal("show");

                });

            }
        },

        // Portfolio listing page
        'post_type_archive_portfolio': {
            finalize: function () {
                // JavaScript to be fired on the about us page

                var isoOptions = {
                    itemSelector: '.portfolio-item',
                    layoutMode: 'fitRows'
                };

                // init Isotope
                var $grid = $('.grid').isotope(isoOptions);

                // filter functions
                var filterFns = {
                    // show if number is greater than 50
                    numberGreaterThan50: function () {
                        var number = $(this).find('.number').text();
                        return parseInt(number, 10) > 50;
                    },
                    // show if name ends with -ium
                    ium: function () {
                        var name = $(this).find('.name').text();
                        return name.match(/ium$/);
                    }
                };

                // bind on filter checkbox click's
                $('.container').on('click', '.filtertick input[type=checkbox]', function () {

                    var filters_list = [];
                    $('.filtertick input[type=checkbox]').each(function () {
                        if (this.checked) {
                            filters_list.push('.' + $(this).data("filter"));
                        }
                    });

                    filters_list = filters_list.join(', ');
                    $grid.isotope({filter: filters_list});

                });

                // change is-checked class on buttons
                $('.button-group').each(function (i, buttonGroup) {
                    var $buttonGroup = $(buttonGroup);
                    $buttonGroup.on('click', 'button', function () {
                        $buttonGroup.find('.is-checked').removeClass('is-checked');
                        $(this).addClass('is-checked');
                    });
                });

                $grid.on('layoutComplete', function () {
                    console.log('Layout');
                    const observer = lozad();
                    observer.observe();
                })

            }
        },
        // Contact us page
        'page_template_template_contact': {
            finalize: function () {

                // Google Analytics tracking

                $("body").on("click", "#gform_submit_button", function () {
                    ga('send', 'event', {
                        eventCategory: 'Contact page',
                        eventAction: 'Submit form',
                    });
                });

            }
        },

        // Quote calculator
        'page_template_template_quote': {
            finalize: function () {

                //Add .active to first step if users scrolls down

                $(function () {
                    //caches a jQuery object containing the header element
                    var step1 = $("#step-1");
                    $(window).scroll(function () {
                        var scroll = $(window).scrollTop();

                        if (scroll >= 250) {
                            step1.addClass("active");
                        }
                    });
                });

                // jump to next input box
                $("#ceiling-height-part-1").keyup(function () {
                    if (this.value.length == this.maxLength) {
                        $("#ceiling-height-part-2").focus().select();
                    }
                });

                // ???
                $("#ceiling-height-part-1,#ceiling-height-part-2").keyup(function () {
                    $("#ceiling-height").val($("#ceiling-height-part-1").val() + '.' + $("#ceiling-height-part-2").val());
                });

                // Show step function
                function showstep(oldstep, newstep) {

                    // show next step
                    $("#step-" + newstep).addClass("active");

                    // scroll to next step
                    $('html,body').animate({scrollTop: ($("#step-" + newstep).offset().top - 400)}, 500);

                }


                // show field errors function
                function showerrors(errors) {

                    if (errors.fields) {

                        $.each(errors.fields, function (index, value) {

                            err_field = $('input[name="' + index + '"]');

                            $(err_field).addClass('error');
                            $("<span class='field-err-msg field-err-msg-" + index + "'>" + value + "</span>").insertAfter(err_field);

                        });
                    }

                    // if session expires boot user to start
                    if (errors.session_expired) {
                        alert('Your session has expired, please re-enter your details again.');
                        reset_form();
                    }

                }


                // reset form

                function reset_form() {
                    $(".calc-step.active").removeClass('active');
                    $("#step-1").addClass('active');
                }

                // update dynamic content i.e. calculations
                function update_frontend(frontend_data) {

                    // loop through each data item
                    $.each(frontend_data, function (index, value) {

                        // target element we are going to insert content inside
                        update_target = $("#" + index);

                        // if the element exists then insert calculated data
                        if ($(update_target).length)
                            $(update_target).html(value);
                    });
                }

                // clear error messages if on the screen
                function clearerrors() {
                    $('input.error').removeClass('error');
                    $('.field-err-msg').fadeOut(300, function () {
                        $(this).remove();
                    });
                }

                // toggle loading icon
                function toggleloadingico(button, show) {
                    if (show)
                        $(button).addClass("loading");
                    else
                        $(button).removeClass("loading");
                }

                // detect click next step button
                $(".calc-step-button").click(function (e) {


                    // if a doors fields is empty then insert a 0
                    if ($('#how-many-glass-doors').val() == '') $('#how-many-glass-doors').val(0);
                    if ($('#how-many-timber-doors').val() == '') $('#how-many-timber-doors').val(0);

                    // clear old errors
                    clearerrors();

                    // show loading anim
                    var clicke = this;
                    toggleloadingico(clicke, true);

                    // prevent traditional submit
                    e.preventDefault();

                    // collect field data and convert to JSON array
                    var form = $(this).closest("form");
                    var formdata = $(form).serializeArray();
                    var step = $(form).find('input[name="step"]').val();


                    jQuery.post(
                        ajaxurl + '?action=quotecalc', formdata,
                        function (response) {

                            // convert json to array
                            response = JSON.parse(response);

                            if (response.success) {

                                /* ============
                                 success
                                 ============ */

                                // show new step and jump to it
                                if (response.success.go_to_step) showstep(step, response.success.go_to_step);

                                // update data on frontend if applicable
                                if (response.success.frontend_data) {
                                    update_frontend(response.success.frontend_data);

                                    // track success with google
                                    ga('send', 'event', {
                                        eventCategory: 'Free Quote',
                                        eventAction: 'Step 4 Customer finished the quote and received result',
                                    });

                                    // track success with bing
                                    window.uetq = window.uetq || [];
                                    window.uetq.push({
                                        'ec': 'Free Quote',
                                        'ea': 'Step 4 Customer finished the quote and received result'
                                    });

                                }


                            } else if (response.errors) {

                                /* ============
                                 error
                                 ============ */

                                // display errors
                                showerrors(response.errors);

                            } else if (response.redirect) {

                              window.location = response.redirect;

                            }

                            // hide loading anim
                            toggleloadingico(clicke, false);

                        }
                    );

                });

                // Detect when user adjusts quotation
                $('.calc-step').on('input', function () {

                    // Get id of current section we are modifying
                    current_section_id = $(this).closest("section").attr("id").split('-')[1];

                    $(".calc-step.active").each(function (i) {

                        // Find all active sections visible
                        visible_section_id = $(this).attr("id").split('-')[1];

                        // Hide sections that are shown above section that is currently being edited
                        if (visible_section_id > current_section_id)
                            $(this).removeClass("active");

                    });

                });

                // Call me back pass through data from calculated quote
                $("#quote-call-me-back-button").click(function () {

                    // Show call back modal
                    $("#modal-call-back").modal('show');

                    // Retrieve data from quote
                    quote_name = $("#step-1 input#name").val();
                    quote_phone = $("#step-1 input#phone").val();
                    quote_reference = "#" + $("#order-summary-reference").text();

                    // Populate call back fields
                    $("#modal-call-back #input_2_1").val(quote_name); // name
                    $("#modal-call-back #input_2_2").val(quote_phone); // telephone
                    $("#modal-call-back #input_2_4").val(quote_reference); // quote reference

                });


                // Google analytics tracking

                $("body").on("click", ".quote-hero .calc-step-button", function () {
                    ga('send', 'event', {
                        eventCategory: 'Free Quote',
                        eventAction: 'Clicked on Lets Begin button',
                    });
                });

                $("body").on("click", "#step-1 .calc-step-button", function () {
                    ga('send', 'event', {
                        eventCategory: 'Free Quote',
                        eventAction: 'Step 1 Entered details and clicked continue',
                    });
                });

                $("body").on("click", "#step-2 .calc-step-button", function () {
                    ga('send', 'event', {
                        eventCategory: 'Free Quote',
                        eventAction: 'Step 2 Entered dimensions and clicked continue',
                    });
                });

                $("body").on("click", "#step-3 .calc-step-button", function () {
                    ga('send', 'event', {
                        eventCategory: 'Free Quote',
                        eventAction: 'Step 3 Entered door information and clicked continue',
                    });
                });

            }
        }

    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
